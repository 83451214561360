<template>
  <section class="dtc-main-section mt-4  page-break" id="dtc-print-it2">
    <header
      class="dtc-title"
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;"
    >
      109學年度臺北醫學大學原住民族及離島地區醫事人員養成計畫公費生
    </header>
    <header
      style="font-size: 20px; display: grid; place-items: center;font-family:DFKai-sb;border-bottom: 1px solid black;height:50px"
    >
      <div style="border:0px">
        {{ school }}學校{{ year }}學年度{{
          term == 1 ? "上" : "下"
        }}暑期課程學分養補助申請清冊
      </div>
    </header>

    <!-- headers -->
    <header class="grid-12">
      <div
        :style="
          i != 5 && i != 7 && i != 9 ? 'padding-top:20px;' : 'padding-top:2px;'
        "
        v-for="(item, i) in names"
        :key="i"
        v-html="item"
      ></div>
    </header>

    <!-- dataset -->
    <main class="grid-12" v-for="(item, i) in tableData" :key="i">
      <div>{{ item.index }}</div>
      <div>{{ item.name }}</div>
      <div>{{ item.from }}</div>
      <div>{{ item.major }}</div>
      <div class="dtc-my-classes">
        <main v-for="(item, i) in item.studies.data" :key="i">
          {{ item.studyName }}
        </main>
      </div>
      <div class="dtc-my-classes">
        <span v-for="(item, i) in item.studies.data" :key="i">{{
          item.studyHours
        }}</span>
      </div>
      <div class="dtc-my-classes">
        <span v-for="(item, i) in item.studies.data" :key="i">{{
          item.grades
        }}</span>
      </div>
      <div class="dtc-my-classes">
        <main v-for="(item, i) in item.studies.data" :key="i" class="grid-2">
          <div>
            {{ $formatPrice(item.uniAmount) }}
          </div>
          <div>
            {{ $formatPrice(item.subTotal) }}
          </div>
        </main>
      </div>
      <div class="dtc-my-classes">
        <span v-for="(item, i) in item.studies.data" :key="i">{{
          $formatPrice(item.payedAmount)
        }}</span>
      </div>
      <div>{{ $formatPrice(item.totalPayAmount) }}</div>
      <div>
        {{ item.signature }}
      </div>
      <div style="font-size:12px;text-align:left;" class="grid-1-3">
        <div v-for="(item, i) in item.studies.data" :key="i" class="remark">
          <p>{{ item.remark }}</p>
        </div>
      </div>
    </main>

    <main class="grid-1-4 field-style">
      <div class="dtc-my-classes">總計申請</div>
      <div>{{ $formatPrice(111111) }}</div>
      <div></div>
      <div></div>
    </main>

    <!-- extends -->
    <div style="border:0px">
      <div class="footerWord test">
        <div>註冊組</div>
        <div>出納組</div>
        <div>財務處</div>
        <div>校長</div>
      </div>
    </div>
  </section>
</template>

<script>
const names = [
  "序號",
  "姓名",
  "籍屬",
  "系別",
  "修習科目",
  "學分 <br/>/上課時數",
  "成績",
  "<div class='dtc-spend-101'><span style='grid-column:1/-1;border-bottom:1px solid black;'>暑期班費用</span><span style='border-right:1px solid black;'>每學分費用</span><span>小計</span></div>",
  "實際支付費用",
  "總計金額<br/><span style='font-size:12px;'>(同一名學生)</span>",
  "簽領",
  "備註",
];
//alert(names.length);
import queryString from "query-string";

export default {
  name: "totalSpend101",
  data() {
    return {
      tableData: [],
      year: 108,
      names,
      term: 1,
      school: "中國醫藥大學",
      amount: 0,
      chineseAmount: "",
      map: {},
      allListItems: [],
      recommend: "",
      createName: "",
    };
  },
  methods: {
    async getData() {
      const {
        schoolcode: SchoolCode = "0003",
        byear: Byear = "109",
        term = 1,
        category: Category = "S",
      } = queryString.parse(location.href.split("?")[1]);
      this.term = term; // == 1 ? '上' : '下';
      this.year = Byear;
      let schoolArr = await window.axios.get("School/GetAll");
      this.school = schoolArr.find(
        (s) => s.SchoolCode == SchoolCode
      ).SchoolName;
      const url = `RecordAll/Get?byear=${Byear}&term=${term}&schoolcode=${SchoolCode}&category=${Category}`;
      try {
        const map = await window.axios.get(url);
        if (map.length) {
          let items = [];
          this.map = map[0];
          items = map[0].nurse_recordall;
          items = items.map((s) => {
            if (s.Grade == 1) {
              s.GradeName = "一";
              return s;
            } else if (s.Grade == 2) {
              s.GradeName = "二";
              return s;
            } else if (s.Grade == 3) {
              s.GradeName = "三";
              return s;
            } else if (s.Grade == 4) {
              s.GradeName = "四";
              return s;
            }
          });
          items.sort(function(a, b) {
            return a.Grade - b.Grade;
          });
          this.recommend = map[0].Recommend;
          this.createName = map[0].CreateName;
          this.allListItems = items;
        }
      } catch (e) {
        const str = e + " API: " + url;
        this.$root.$emit("dtc-server-error", str);
      }
    },
    async getData1() {
      var step;
      let dataSet = [];
      let subData = [];

      for (step = 1; step < 4; step++) {
        let record = {
          studyName: "大學解剖學實驗",
          studyHours: "2.5",
          grades: "77.7",
          uniAmount: "2000",
          subTotal: "5000",
          payedAmount: "22000",
          remark: "大體解剖學大體解剖學 ",
        };
        subData.push(record);
      }

      for (step = 1; step < 16; step++) {
        let record = {
          index: step,
          name: "曹茖凱",
          from: "連江縣",
          major: "醫學系",
          studies: {
            data: subData,
          },
          totalPayAmount: "46909",
          signature: "",
          remarks: "remarks",
        };
        dataSet.push(record);
      }
      this.tableData = dataSet;
    },
  },
  async mounted() {
    const {
      schoolcode: SchoolCode = "0003",
      byear: Byear = "109",
      term = "1",
      category: Category = "S",
    } = queryString.parse(location.href.split("?")[1]);
    this.term = +term;
    Byear ? (this.bYear = Byear) : (this.bYear = "109");
    this.getData1();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$table-width: 1250px;
$width-peice: 20px;

@media all {
  .page-break {
  }
}

@media print {
  .page-break {
    display: block;
    page-break-before: always;
  }
}

@function field-width($percent: 1) {
  @return $table-width * $percent;
}
@function cal-peice($peice: 1) {
  @return $width-peice * $peice;
}

.remark {
  width: 100%;
  height: 100%;
  line-height: 12px;
  > p {
    display: inline;
    padding: 0px;
    margin: 0px;
    vertical-align: top;
    line-height: 15px;
  }
}
.grid-1-3 {
  grid-template-rows: 1fr 1fr 1fr;
  > div {
    border-bottom: 1px solid black;
  }
}
.dtc-main-section {
  * {
    word-break: break-all;
  }
  margin: 0 auto;
  width: $table-width;
}
.dtc-header {
  border: 1px solid black;
  height: 80px;
  font-size: 24px;
  text-align: center;
}
.h2 {
  border: 1px solid black;
  border-top: none;
  height: 40px;
  font-size: 18px;
  text-align: left;
  line-height: 40px;
  padding-left: 5px;
  font-weight: bold;
  margin-bottom: 0;
}

.grid-1-4 {
  display: grid;
  border: 1px solid black;
  min-height: 33px;
  border-top: none;
  grid-template-columns: field-width(0.712) field-width(0.08) field-width(0.088) field-width(
      0.035
    );
}
.field-style {
  > div {
    border-right: 1px solid black;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    position: relative;
    display: grid;
    place-items: center;
  }
  > div:last-child {
    border-right: none;
  }
}
.grid-12 {
  display: grid;
  border: 1px solid black;
  min-height: 30px;
  border-top: none;
  grid-template-columns:
    field-width(0.03) field-width(0.07) field-width(0.042) field-width(0.08) field-width(
      0.11
    )
    field-width(0.08) field-width(0.05) field-width(0.18) field-width(0.07) field-width(
      0.08
    ) field-width(0.088)
    field-width(0.118);
  > div {
    border-right: 1px solid black;
    height: 100%;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    position: relative;
    display: grid;
    place-items: center;
  }
  > div:last-child {
    border-right: none;
  }
  .dtc-my-classes {
    place-items: unset;
  }
}

footer {
  border: 1px solid black;
  border-top: none;
  min-height: 140px;
  font-size: 13px;
  text-align: left;
  padding-left: 2px;
  padding-top: 10px;
}
.footerWord {
  width: 100%;
  font-size: 19px;
  display: grid;
  padding-left: 120px;
  padding-top: 30px;
  padding-bottom: 130px;
  grid-template-columns: 23% 23% 23% auto;
}
.dtc-my-classes {
  text-align: left !important;
  > main {
    height: 40px;
    line-height: 1.2em;
    …padding-left: 2px;
    border-bottom: 1px solid black;
    word-break: break-all;
    vertical-align: middle;
  }
  > main:last-child {
    border: none;
  }
  > span {
    height: 40px;
    border-bottom: 1px solid black;
    vertical-align: middle;
    text-align: center;
  }
  > span:last-child {
    border: none;
  }
}
.dtc-spend {
  display: grid;
  grid-template-columns: 120px 1fr;
}
.grid-2 {
  display: grid;
  grid-template-columns: 55% 1fr;
  text-align: right;
  > div:first-child {
    border-right: 1px solid black;
    padding-right: 10px;
  }
}
</style>

<style lang="scss">
.dtc-spend-101 {
  display: grid;
  grid-template-rows: 44px 1fr;
  grid-template-columns: 55% 1fr;
  height: 100%;
  width: 100%;
  text-align: center;
}
</style>
